<template>
  <div class="container">
    <div>

      <div class="image-container">
        <img :src="introImage" alt="" v-show="introImageLoaded">
      </div>
      <br />
      <button @click="goHome" :style="{ color: $store.state.textColor }">enter</button>
    </div>

  </div>
</template>

<style scoped>
html,
body {
  background-color: white;
}

@media (prefers-color-scheme; dark) {

  html,
  body {
    background-color: white;
  }
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.image-container {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .image-container {
    width: 500px;
  }
}

img {
  width: 100%;
  margin-bottom: 3rem;
  object-fit: contain;
}

button {
  width: 100px;
  height: 50px;
  background-color: transparent;
  color: black;
  border: none;
  font-size: 18px;
  cursor: pointer;
}
</style>

<script>
export default {
  name: "intro",
  data: () => ({
    textColor: "",
  }),
  // mounted: async () => {
  //   await this.getColor()
  // },
  methods: {
    async goHome() {
      await this.getColor();
      this.$router.push("/home");
    },
    async getColor() {
      const r = await this.$firebase
        .firestore()
        .collection('Look')
        .doc('look')
        .get();

      this.$store.commit('changeTextColor', r.data().textColor);
      // this.$store.commit('changeIntro', r.data().introImage);
    },
  },
  computed: {
    introImage: function () {
      // return this.$route.fullPath === '/' ? '' :
      return this.$store.state.introImage;
    },
    introImageLoaded: function () {
      return this.$store.state.introImage != false;
    }
  },
};
</script>